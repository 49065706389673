<template>
  <div class="qr-presets">
    <div class="qr-presets__header">
      <h3 class="qr-presets__header-text">{{ title }}</h3>
      <img
        class="qr-presets__header-image"
        width="30px"
        height="30px"
        src="/img/buttonClose.png"
        srcset="/img/buttonClose@3x.png 3x /img/buttonClose@2x.png 2x /img/buttonClose.png 1x"
        @click="close()"
      />
    </div>
    <div v-if="$slots.actions" class="qr-presets__actions">
      <slot name="actions" />
    </div>
    <div class="qr-presets__body">
      <div v-if="presetsTitle" class="qr-presets__presets-title">{{ presetsTitle }}</div>
      <div class="qr-presets__presets">
        <div
          v-for="(preset, index) in presets"
          :key="preset.title"
          :class="[
            'qr-presets__preset',
            currentPresetIndex === index && 'qr-presets__preset--active',
          ]"
          @click="selectPreset(index)"
        >
          <div class="qr-presets__persents">{{ preset.title }}</div>
          <div class="qr-presets__amount">
            {{ currencySymbol }} {{ preset.value }}
          </div>
        </div>
      </div>
      <input
        v-model="inputAmount"
        class="qr-presets__input"
        :placeholder="$t('qrOrder.otherAmount')"
        type="tel"
      />
      <div role="button" class="qr-presets__action" @click="selectAmount">
        OK
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    presets: {
      type: Array,
      default: () => [],
    },
    presetsTitle: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      currentPresetIndex: 1,
      inputAmount: '',
    }
  },
  watch: {
    inputAmount(value) {
        if (value && this.currentPresetIndex > -1) {
            this.currentPresetIndex = -1
        } 
    }
  },
  methods: {
    selectPreset(index) {
      this.inputAmount = ''
      this.currentPresetIndex = index
    },
    selectAmount() {
      this.$emit(
        'select',
        this.inputAmount
          ? Number(this.inputAmount)
          : this.presets[this.currentPresetIndex].value
      )
      this.inputAmount = ''
    },
    close() {
      this.$emit('close')
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.qr-presets {
  @include flex(column, flex-start, center);
  position: fixed;
  // height: 346px;
  inset: 0;
  top: unset;
  background-color: #333333;
  border-radius: 24px 24px 0px 0px;
  z-index: 3;

  &__header,
  &__body {
    width: 100%;
  }
  &__header {
    height: 68px;
    @include flex(row, space-between, center);
  }

  &__header-text {
    margin-left: 16px;
    @include font(20, 24, 600);
    color: $white;
  }

  &__header-image {
    margin-right: 19px;
  }

  &__body {
    flex: 1;
    background: rgba(0, 0, 0, 0.2);
    padding: 30px 16px 42px 16px;
    @include flex(column, center, center);
    gap: 16px;
  }

  &__presets {
    width: 100%;
    @include flex(row, flex-start, center);
    gap: 11px;
  }

  &__preset {
    flex: 1;
    height: 64px;
    background-color: #333333;
    border-radius: 8px;
    color: rgba(171, 171, 171, 1);
    @include flex(column, center, center);
    gap: 3px;

    &--active {
      border: 3px solid $goldenMain;
      transition: all 0.15s ease;
      background-color: transparent;

      .qr-presets__persents {
        color: $white;
        @include font(25, 28, 600);
      }

      .qr-presets__amount {
        color: rgba(148, 148, 148, 1);
        @include font(18, 21, 400);
      }
    }
  }

  &__persents {
    @include font(18, 21, 600);
    transition: all 0.15s ease;
  }

  &__amount {
    @include font(14, 15, 500);
    transition: all 0.15s ease;
  }

  &__input {
    width: 100%;
    height: 48px;
    background: transparent;
    border: 1px solid rgba(102, 102, 102, 1);
    outline: none;
    border-radius: 8px;
    color: $white;
    text-align: center;
    @include font(18, 21, 400);

    &::placeholder {
      color: rgba(148, 148, 148, 1);
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: rgba(148, 148, 148, 1);
    }
  }

  &__action {
    width: 100%;
    height: 48px;
    margin-top: 14px;
    background-color: $goldenMain;
    border-radius: 24px;
    @include font(15, 18, 600);
    @include flex(column, center, center);
    color: $black;
  }

  &__presets-title {
    @include font(18, 21, 500);
    color: $white;
    text-align: left;
    width: 100%;
  }

  &__actions {
    width: 100%;
    padding: 0 16px;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 5px solid #333333;
  }
}
</style>
