import axios from 'axios'
import { API_URL } from './utils'

/**
 *
 * @param {string} checkinUid
 * @param {string} paymentType
 * @param {string} paymentSourceUid
 * @returns { Promise }
 */
export async function getPlaceByUid(
  placeUid
) {
  try {
    const {
      data: { error_code: errorCode, description, payload: { place } },
    } = await axios.get(API_URL(`/place/byUid/${placeUid}`))

    if (errorCode) {
      return { error: { code: errorCode, description } }
    }
    return { place }
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: { status: 401 } }
    }
    throw Error(error)
  }
}
