<template>
	<div class="ch-l-m__backdrop" :class="{'-open': open}" @click="closeMe">
		<div class="ch-l-m" @click="$emit('input', false)" :class="{'-open': open}">
			<div class="ch-l-m__head-top"></div>
			<img 
				width="20px" 
				height="20px" 
				src="/img/closeWhite.svg" 
				class="ch-l-m__head-close"
				@click="closeMe"
			>
			<div class="ch-l-m__list">
				<div 
					class="ch-l-m__list-item" 
					v-for="(lang, i) in langs" 
					:key="i"
					@click="translate(lang.key)"
				>
					<div 
						class="ch-l-m__list-item__title"
						:class="{'-active': currentLang === lang.key}"
					>
						<!-- <img src="/img/Mask.svg"> -->
						{{ lang.nativeName }}
					</div>
					<!-- <div class="ch-l-m__list-item__subtitle">
						{{ lang.userLangName }}
					</div> -->
				</div>
				<!-- <div class="ch-l-m__list-item__subtitle">
					{{ lang.userLangName }}
				</div> -->
			</div>
			<!-- close -->
		</div>
	</div>
</template>

<script>
import allLangs from '@/assets/data/iso3langs.json'

export default {
	name: 'ChooseLanguageModal',
	data() {
		return {
			langs: [],
			open: false
		}
	},
	computed: {
		currentLang() {
			return this.$iso3Lang
		}
	},
	methods: {
		translate(key) {
			this.$emit('chooseLang', key)
			this.open = false
		},
		closeMe() {
			this.open = false
			this.$emit('chooseLang', false)
		},
		show(placeLangs=[]) {
			return new Promise((resolve) => {
				const { langs } = this,
					userISO3 = this.$iso3Lang,
					_allLangs = allLangs
				langs.splice(0, langs.length)
				this.$on('chooseLang', (lang) => {
					this.$off('chooseLang')
					this.open = false
					resolve(lang)
				})
				langs.push(...placeLangs.map((key) => {
					const language = _allLangs[key]
					return {
						...language,
						userLangName: language?.translates?.[userISO3] || language.name,
						key
					}
				}))
				this.open = true
			})
		}
	}
}
</script>

<style lang="scss">

	.ch-l-m {
		position: fixed;
		left: 0;
		visibility: hidden;
		bottom: 0;
		width: 100%;
		background: rgba(28, 28, 30, 0.7);
		border-radius: 10px 10px 0px 0px;
		transform: translateY(100%);
		transition: all 0.3s ease-in-out;

		&__backdrop {
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			visibility: hidden;
			z-index: 500;

			&.-open {
				visibility: visible;
			}
		}

		&.-open {
			transform: translateY(0);
			visibility: visible;
		}
		
		&__head {
			width: 100%;
			height: 59px;
			position: relative;
			@include flex(row, center, center);
			@include font(20, 24, 400);
			letter-spacing: -0.923077px;
			color: $white;

			&-close {
				position: absolute;
				top: 18px;
				right: 18px;
				z-index: 2;
			}

			&-top {
				@include pos-centered-h;
				height: 4px;
				width: 32px;
				top: 7px;
				z-index: 2;
				background: #C4C4C4;
				border-radius: 10px;
			}
		}

		&__list {
			width: 100%;
			@include flex(column, flex-start, flex-start);
			margin-top: 24px;
			margin-bottom: 40px;
			height: calc(100% - 100px);
			overflow: scroll;

			&-item {
				width: 100%;
				// height: 60px;
				color: $white;
				text-align: left;
				margin: 15px 0px;
				letter-spacing: -0.923077px;
				text-align: center;

				&__title {
					@include font(20, 24, normal);
					position: relative;
					text-transform: uppercase;

					&.-active {
						@include font(24, 28, 600);
						color: $goldenMain;
					}

					img {
						position: absolute;
						width: 6px;
						height: 10px;
						top: 6px;
						left: -12px;
					}
				}

				&__subtitle {
					@include font(17, 20, normal);
					color: #C1C1C1;
				}
			}
		}
	}
</style>