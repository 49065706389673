<template>
  <div class="qr-split">
    <QrPresetDrawer
      :title="$t('qrOrder.splitTypes')"
      :currencySymbol="currencySymbol"
      :presets="tipsPresets"
      :presetsTitle="$t('qrOrder.inEqualParts')"
      @select="onAmountSelect"
      @close="onDrawerClose"
    >
      <template #actions>
        <div class="qr-split__action" @click="startSlitMode">
          {{ $t('qrOrder.byPositions') }}
          <img
            src="/img/backWhite.png"
            srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
            class="qr-split__action-icon"
            width="24px"
            height="24px"
          />
        </div>
      </template>
    </QrPresetDrawer>
  </div>
</template>

<script>
import QrPresetDrawer from './QrPresetDrawer'

export default {
  props: {
    currencySymbol: {
      type: String,
      default: '',
    },
  },
  components: {
    QrPresetDrawer,
  },
  data() {
    return {
      currentPresetIndex: 1,
    }
  },
  computed: {
    tipsPresets() {
      return [
        {
          title: '1/2',
          value: 1450,
        },
        {
          title: '1/3',
          value: 967,
        },
        {
          title: '1/4',
          value: 725,
        },
      ]
    },
  },
  methods: {
    selectPreset(index) {
      this.currentPresetIndex = index
    },
    onAmountSelect(amount) {
      this.$emit('splitApply', amount)
    },
    startSlitMode() {
      this.$emit('startSplitMode')
    },
    onDrawerClose() {
      this.$emit('splitDrawerClose')
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.qr-split {
  &__action {
    @include font(18, 21, 500);
    color: $white;
    text-align: left;
    width: 100%;
    height: 60px;
    @include flex(row, space-between, center);
  }

  &__action-icon {
    transform: rotate(180deg);
    margin-right: 8px;
  }

  ::v-deep .qr-presets__body {
    padding-top: 20px;
  }
}
</style>
