<template>
  <div class="qr-order">
    <QrOrderBill
      v-show="isShowPayment"
      :items="items"
      :currencySymbol="currencySymbol"
    />
    <div class="qr-order__list">
      <QrOrderLitItem
        v-for="item in items"
        :key="item.uid"
        :item="item"
        :currencySymbol="currencySymbol"
        :isSelected="checkIsItemSelected(item.uid)"
        :isSplitMode="isSplitMode"
        @select="onItemSelect(item.uid)"
      />
    </div>
    <div v-if="!!isShowPayment" class="qr-order__actions">
      <div role="button" class="qr-order__action" @click="onPayClick">
        {{ $t('qrOrder.toPay') }}
      </div>
      <div
        v-if="isSplitMode"
        role="button"
        class="qr-order__action"
        @click="isSplitMode = false"
      >
        {{ $t('qrOrder.toCancel') }}
      </div>
      <div
        v-else
        role="button"
        class="qr-order__action"
        @click="showSplitDrawer"
      >
        {{ $t('qrOrder.splitBill') }}
      </div>
    </div>
    <QrOrderTips
      v-if="isTipsDrawerOpen"
      :currencySymbol="currencySymbol"
      @tipsDrawerClose="closeTipsDrawer"
    />
    <QrOrderBillSplit
      v-if="isSplitDrawerOpen"
      :currencySymbol="currencySymbol"
      @splitApply="splitApplyHandler"
      @startSplitMode="startSplitModeHandler"
      @splitDrawerClose="closeSplitDrawer"
    />
  </div>
</template>

<script>
import { CURRENCIES } from '@/consts'
import QrOrderBill from './QrOrderBill'
import QrOrderLitItem from './QrOrderLitItem'
import QrOrderTips from './QrOrderTips'
import QrOrderBillSplit from './QrOrderBillSplit'
import payMixin from '@/mixins/payMixin'

export default {
  mixins: [payMixin],
  components: {
    QrOrderBill,
    QrOrderLitItem,
    QrOrderTips,
    QrOrderBillSplit,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isTipsDrawerOpen: false,
      isSplitDrawerOpen: false,
      isSplitMode: false,
      selectedItems: [],
    }
  },
  computed: {
    activeCheckin() {
      return this.$checkins?.[0]
    },
    currencySymbol() {
      return CURRENCIES[this.activeCheckin?.currency || 'USD']
    },
    isShowPayment() {
      return !!Number(this.leftToPay)
    },
  },
  methods: {
    checkIsItemSelected(uid) {
      return this.selectedItems.includes(uid)
    },
    showSplitDrawer() {
      this.isSplitDrawerOpen = true
    },
    closeSplitDrawer() {
      this.isSplitDrawerOpen = false
    },
    splitApplyHandler(amount) {
      this.closeSplitDrawer()
    },
    startSplitModeHandler() {
      this.isSplitMode = true
      this.closeSplitDrawer()
    },
    onItemSelect(uid) {
      if (this.checkIsItemSelected(uid)) {
        this.selectedItems = this.selectedItems.filter(
          (itemId) => itemId !== uid
        )
      } else {
        this.selectedItems.push(uid)
      }
    },
    showTipsDrawer() {
      this.isTipsDrawerOpen = true
    },
    closeTipsDrawer() {
      this.isTipsDrawerOpen = false
      this.$emit('pay')
    },
    onPayClick() {
      this.showTipsDrawer()
    },
  },
}
</script>

<style lang="scss">
.qr-order {
  background: $black !important;

  &__list {
    @include flex(column, flex-start, center);
    gap: 4px;
    width: 100%;
  }

  &__actions {
    position: fixed;
    height: 48px;
    bottom: 42px;
    left: 16px;
    right: 16px;
    z-index: 2;
    background: rgba(51, 51, 51, 1);
    border-radius: 24px;
    padding: 2px;
    gap: 7px;
    @include flex(row, flex-start, center);
  }

  &__action {
    width: 100%;
    border-radius: 24px;
    height: 100%;
    background: $white;
    color: $black;
    @include flex(row, center, center);
    @include font(15, 18, 600);
    text-transform: uppercase;

    &--hidden {
      width: 0;
      visibility: hidden;
    }

    &:last-child {
      background: rgba(255, 255, 255, 0.1);
      color: $white;
    }
  }
}
</style>
