<template>
  <div class="qr-tips">
    <QrPresetDrawer
      title="Чаевые официанту"
      :currencySymbol="currencySymbol"
      :presets="tipsPresets"
      @select="onAmountSelect"
      @close="onDrawerClose"
    />
  </div>
</template>

<script>
import QrPresetDrawer from './QrPresetDrawer'

export default {
  props: {
    currencySymbol: {
      type: String,
      default: '',
    },
  },
  components: {
    QrPresetDrawer,
  },
  data() {
    return {
      currentPresetIndex: 1,
    }
  },
  computed: {
    tipsPresets() {
      return [
        {
          title: '10%',
          value: 163,
        },
        {
          title: '15%',
          value: 244.5,
        },
        {
          title: '20%',
          value: 326,
        },
      ]
    },
  },
  methods: {
    selectPreset(index) {
      this.currentPresetIndex = index
    },
    onAmountSelect(amount) {
      console.log('amount', amount)
    },
    onDrawerClose() {
      this.$emit('tipsDrawerClose')
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
</style>
