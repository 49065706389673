<template>
  <div class="order-bill">
    <div class="order-bill__row">
      <div class="order-bill__col">{{ $t('qrOrder.billAmount') }}</div>
      <div class="order-bill__col">{{ currencySymbol }} {{ billRequired }}</div>
    </div>
    <div
      v-for="(addPayment, i) in mixinAdditionalCustomPayments"
      :key="i"
      class="order-bill__row"
    >
      <div class="order-bill__col">{{ addPayment.title }}</div>
      <div class="order-bill__col">
        {{ currencySymbol }} {{ addPayment.value }}
      </div>
    </div>
    <div v-if="unpaidServiceFee" class="order-bill__row">
      <div class="order-bill__col">{{ $t('order.serviceFee') }}</div>
      <div class="order-bill__col">
        {{ currencySymbol }} {{ unpaidServiceFee }}
      </div>
    </div>
    <div class="order-bill__row">
      <div class="order-bill__col">{{ $t('qrOrder.total') }}</div>
      <div class="order-bill__col order-bill__col--total">
        {{ currencySymbol }} {{ calculatedUnpaid.total }}
      </div>
    </div>
  </div>
</template>

<script>
import payMixin from '@/mixins/payMixin'
import additionalPaymentsMixin from '@/mixins/additionalPaymentsMixin'

export default {
  mixins: [payMixin, additionalPaymentsMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currencySymbol: {
      type: String,
      default: '',
    },
  },
  computed: {
    billRequired() {
      return this.items.reduce(
        (accumulator, item) => (accumulator += Number(item.price)),
        0
      )
    },
    totalPaid() {
      return this.items.reduce((accumulator, item) => {
        if (item.paid) {
          accumulator += Number(item.price)
        }
        return accumulator
      }, 0)
    },
    total() {
      return this.billRequired - this.totalPaid
    },
  },
}
</script>

<style lang="scss">
.order-bill {
  @include flex(column, flex-start, center);
  padding: 22px 16px;
  margin-bottom: 4px;
  background: #1a1a1a;
  @include font(18, 21, 400);
  color: $white;
  gap: 14px;

  &__row {
    @include flex(row, space-between, center);
    width: 100%;
    border-bottom: 2px dotted #333333;
  }

  &__col {
    background: #1a1a1a;
    transform: translateY(6px);
    padding-right: 5px;
    padding-left: 0;

    &:last-child {
      padding-right: 0;
      padding-left: 5px;
    }

    &--total {
      color: $goldenMain;
    }
  }
}
</style>
